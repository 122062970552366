import _fetch from "./request";
// 获取列表
export function examList(query) {
  return _fetch({
    url: '/admin/exam/list',
    method: 'get',
    params: query,
    needToken:true
  })
}
// 增
export function examSave(data) {
    return _fetch({
      url: '/admin/exam/save',
      method: 'post',
      data,
      needToken:true
    })
}
// 删
export function examDelete(data) {
    return _fetch({
      url: '/admin/exam/delete',
      method: 'post',
      data,
      needToken:true
    })
}
// 改
export function examUpdate(data) {
    return _fetch({
      url: '/admin/exam/update',
      method: 'post',
      data,
      needToken:true
    })
}
// 编辑
export function examEdit(query) {
    return _fetch({
      url: '/admin/exam/edit',
      method: 'get',
      params: query,
      needToken:true
    })
}
// 试题题目
// 获取列表
export function examQList(query) {
    return _fetch({
      url: '/admin/examQ/list',
      method: 'get',
      params: query,
      needToken:true
    })
  }
  // 增
  export function examQSave(data) {
      return _fetch({
        url: '/admin/examQ/save',
        method: 'post',
        data,
        needToken:true
      })
  }
  // 删
  export function examQDelete(data) {
      return _fetch({
        url: '/admin/examQ/delete',
        method: 'post',
        data,
        needToken:true
      })
  }
  // 改
  export function examQUpdate(data) {
      return _fetch({
        url: '/admin/examQ/update',
        method: 'post',
        data,
        needToken:true
      })
  }
  // 编辑
  export function examQEdit(query) {
      return _fetch({
        url: '/admin/examQ/edit',
        method: 'get',
        params: query,
        needToken:true
      })
  }
//   考试记录 admin/examH/list
export function examHList(query) {
    return _fetch({
      url: '/admin/examH/list',
      method: 'get',
      params: query,
      needToken:true
    })
}
// 用户查询
export function trainerList(query) {
  return _fetch({
    url: '/admin/trainer/list',
    method: 'get',
    params: query,
    needToken:true
  })
}
// 审核 admin/trainer/verify
export function trainerVerify(data) {
  return _fetch({
    url: '/admin/trainer/verify',
    method: 'post',
    data,
    needToken:true
  })
}
// 反馈 admin/feedback/list
export function feedbackList(query) {
  return _fetch({
    url: '/admin/feedback/list',
    method: 'get',
    params: query,
    needToken:true
  })
}
// 试题详情admin/ehd/list

export function ehdList(query) {
  return _fetch({
    url: '/admin/ehd/list',
    method: 'get',
    params: query,
    needToken:true
  })
}
