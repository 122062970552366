<template>
  <div class="addTopic">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>考核题目列表</span>
        <el-button
          style="float: right; padding: 5px 5px"
          type="success"
          @click="addnews"
          >添加题目</el-button
        >
      </div>
      <el-table ref="multipleTable" :data="tableData" stripe style="width: 100%" >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column prop="title1" label="题目" align="center">
        </el-table-column>
        <el-table-column prop="correct" label="选项" align="center">
        </el-table-column>
        <el-table-column prop="answer" label="正确选项" align="center">
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="addtopicVisible(scope.$index,scope.row)">编辑</el-button>
            <el-button type="text"  class="delete" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 配置题库弹窗 -->
    <el-dialog
      title="配置题库信息"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="cancel"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form ref="form" :model="topicForm">
        <el-form-item>
          <template #default>
            <div class="title">题目</div>
            <el-input v-model="name"></el-input>
          </template>
        </el-form-item>
        <el-form-item label="选项">
          <el-checkbox v-model="is_check.checked1">
            <el-input v-model="topicForm.optionsA">
              <template slot="prepend">A</template>
            </el-input>
          </el-checkbox>
        </el-form-item>
        <el-form-item label="选项">
          <el-checkbox v-model="is_check.checked2">
            <el-input v-model="topicForm.optionsB">
              <template slot="prepend">B</template>
            </el-input>
          </el-checkbox>
        </el-form-item>
        <el-form-item label="选项">
          <el-checkbox v-model="is_check.checked3">
            <el-input v-model="topicForm.optionsC">
              <template slot="prepend">C</template>
            </el-input>
          </el-checkbox>
        </el-form-item>
        <el-form-item label="选项">
          <el-checkbox v-model="is_check.checked4">
            <el-input v-model="topicForm.optionsD">
              <template slot="prepend">D</template>
            </el-input>
          </el-checkbox>
        </el-form-item>
        <el-form-item label="该题型为" class="radioItem">
          <el-radio-group v-model="radio" @change="radioChange">
            <el-radio :label="1">单选题</el-radio>
            <el-radio :label="2">多选题</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" v-if="judge == 1" @click="configuration">确定</el-button>
        <el-button type="primary" v-else @click="update">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {examQList,examQSave,examQDelete,examQUpdate,examQEdit} from '@/api/exam.js'
export default {
  data () {
    return {
      tableData: [],
      dialogVisible: false,
      topicForm: {
        optionsA:null,
        optionsB:null,
        optionsC:null,
        optionsD:null
      },
      is_check: {
        checked1: false,
        checked2: false,
        checked3: false,
        checked4: false
      },
      name: null,
      judge: 1,
      index:null,
      radio:1,
      swithId:null,
      exam_id:null,
      currentPage:1,
      dataList:[],
      obj:[],
      msgData:{}
    }
  },
  created () {
    // 本试题题目的id
    this.exam_id = this.$route.query.id
    this.getList(this.currentPage,this.exam_id)
  },
  methods: {
    // 获取列表
    async getList(pages) {
      const res = await examQList({page:pages,exam_id:this.exam_id})
      if(res.data.code == 1){
         this.tableData = res.data.result.list
         res.data.result.list.forEach((item,index)=>{
           this.obj[index] = [item.A,item.B,item.C,item.D]
           this.tableData[index].correct = this.obj[index]
         })
      }
    },
    //   编辑
    async addtopicVisible (index,row) {
      const res = await examQEdit({id:row.id})
      this.msgData = res.data.result
      if(res.data.result.type == 1) {
        this.radio = 1
      }else {
        this.radio = 2
      }
      // 传参
      this.index = row.id
      this.judge = 2
        this.name = this.msgData.title
        const arr = [this.msgData.A,this.msgData.B,this.msgData.C,this.msgData.D]
        const newArr = this.msgData.answer.split(',')
        newArr.forEach(item=>{
          if(item == 'A') {
            this.is_check.checked1 = true
          }else if(item == 'B') {
            this.is_check.checked2 = true
          }else if(item == 'C') {
            this.is_check.checked3 = true
          }else if(item == 'D') {
            this.is_check.checked4 = true
          }
        })
        this.topicForm.optionsA = this.msgData.A
        this.topicForm.optionsB = this.msgData.B
        this.topicForm.optionsC = this.msgData.C
        this.topicForm.optionsD = this.msgData.D
        this.dialogVisible = true
    },
    // 取消
    cancel () {
      this.name = ''
      this.topicForm.optionsA = ''
      this.topicForm.optionsB = ''
      this.topicForm.optionsC = ''
      this.topicForm.optionsD = ''
      this.is_check.checked1 = ''
      this.is_check.checked2 = ''
      this.is_check.checked3 = ''
      this.is_check.checked4 = ''
      this.dialogVisible = false
      
    },
    addnews () {
      this.dialogVisible = true
      this.judge = 1
    },
    async handleDelete (index,row) {
      const res = await examQDelete({id:row.id})
      if(res.data.code == 1) {
        this.$message.success('删除成功！')
        this.getList(this.currentPage,this.exam_id)
      }
    },
    // 确认添加
    async configuration () {
      const check = [
          this.is_check.checked1,
          this.is_check.checked2,
          this.is_check.checked3,
          this.is_check.checked4
        ]
        const swith = []
        check.forEach((item, index) => {
          if (item == true) {
            switch (parseInt(index)) {
              case 0:
                swith.push('A')
                break;
              case 1:
                swith.push('B')
                break;
              case 2:
                swith.push('C')
                break;
              case 3:
                swith.push('D')
                break;
            }
          }
        })
        const arr = swith.join(',')
      const obj = {
        exam_id:this.exam_id,
        title:this.name,
        A:this.topicForm.optionsA,
        B:this.topicForm.optionsB,
        C:this.topicForm.optionsC,
        D:this.topicForm.optionsD,
        type:this.radio,
        answer:arr
      }
      const res = await examQSave(obj)
      if(res.data.code == 1) {
        this.judge = 1
        this.$message.success('添加成功')
        this.getList(this.currentPage,this.exam_id)
        this.dialogVisible = false
        this.name = ''
        this.topicForm.optionsA = ''
        this.topicForm.optionsB = ''
        this.topicForm.optionsC = ''
        this.topicForm.optionsD = ''
        this.is_check.checked1 = ''
        this.is_check.checked2 = ''
        this.is_check.checked3 = ''
        this.is_check.checked4 = ''
      }
    },
    // 编辑更新
    async update() {
       
      this.judge = 2
      // 重新赋值
        const msg = this.index

        const check = [
          this.is_check.checked1,
          this.is_check.checked2,
          this.is_check.checked3,
          this.is_check.checked4
        ]
        
        const swith = []
        check.forEach((item, index) => {
          if (item == true) {
            switch (parseInt(index)) {
              case 0:
                swith.push('A')
                break;
              case 1:
                swith.push('B')
                break;
              case 2:
                swith.push('C')
                break;
              case 3:
                swith.push('D')
                break;
            }
          }
        })
        const arr = swith.join(',')
        const obj = {
        exam_id:this.exam_id,
        title:this.name,
        A:this.topicForm.optionsA,
        B:this.topicForm.optionsB,
        C:this.topicForm.optionsC,
        D:this.topicForm.optionsD,
        type:this.radio,
        answer:arr,
        id:this.index
      }
      const res = await examQUpdate(obj)
      if(res.data.code == 1){
        this.$message.success('添加成功')
        this.getList(this.currentPage,this.exam_id)
        this.dialogVisible = false
        this.name = ''
        this.topicForm.optionsA = ''
        this.topicForm.optionsB = ''
        this.topicForm.optionsC = ''
        this.topicForm.optionsD = ''
        this.is_check.checked1 = ''
        this.is_check.checked2 = ''
        this.is_check.checked3 = ''
        this.is_check.checked4 = ''
      }
    },
    // 题型选择
    radioChange(event) {
      console.log(event);
    }
  }
}
</script>

<style lang="less" scoped>
.addTopic {
  padding: 20px 0;
  // ::v-deep .el-form-item{
  //    display: flex;
  //   align-items: center;
  // }
  .radioItem{
    display: flex;
    align-items: center;
  }
  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
  }
  ::v-deep .el-checkbox{
    display: flex;
    align-items: center;
  }
  .title {
    width: 73px;
  }
}
</style>
